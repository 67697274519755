// src/MyComponent.jsx
import React, { useState } from 'react';
import './style.css';
import telegramIcon from './image/telegram_icon.png';
import ethereumIcon from './image/trx.png';
import twitterIcon from './image/twitter_icon.png';
import nftImage from './image/nft_body.png';
import LoadingIndicator from './LoadingIndicator';
const { BigNumber } = require('bignumber.js');


// 合约地址
const contractAddress = 'TALQNuUpu4Lf1Z6C9vyGQA1KkAtuheS5FD';
// 合约ABI
const contractABI = [
  // 合约中的方法ABI
  {
    "constant": true,
    "inputs": [],
    "name": "totalSupply",
    "outputs": [{ "name": "", "type": "uint256" }],
    "type": "function",
    "payable": false,
    "stateMutability": "view",
  },
  {
    "constant": true,
    "inputs": [],
    "name": "minted",
    "outputs": [{ "name": "", "type": "uint256" }],
    "type": "function",
    "payable": false,
    "stateMutability": "view",
  },
  {
    "constant": true,
    "inputs": [{ "name": "owner", "type": "address" }],
    "name": "balanceOf",
    "outputs": [{ "name": "", "type": "uint256" }],
    "type": "function",
    "payable": false,
    "stateMutability": "view",
  },
  {
    "constant": false,
    "inputs": [{ "name": "to", "type": "address" }],
    "name": "mint",
    "outputs": [],
    "payable": true,
    "stateMutability": "payable",
    "type": "function"
  }
];


function MyComponent() {
  const [totalSupply, setTotalSupply] = useState(0);
  const [minted, setMinted] = useState(0);
  const [balance, setBalance] = useState(0);
  const [connectedAccount, setConnectedAccount] = useState("");
  const [loading, setLoading] = useState(false);

  const connectToBlockchain = async () => {
    try{
     if (window.tronWeb) {
       if(!window.tronLink.ready){
         window.tronLink.request({method: 'tron_requestAccounts'})
          .then(function(result) {
            console.log(result);
            const address = window.tronWeb.defaultAddress.base58;
            console.log("result",address);
            const shortenedAddress = address.substring(0, 8) + "..." + address.substring(address.length - 4);
            setConnectedAccount(shortenedAddress);
          }).catch(function(err) {
            alert("用户拒绝链接")
          }); 
       }else{
       // 示例: 获取钱包地址
       const address = window.tronWeb.defaultAddress.base58;
       const contract = await window.tronWeb.contract(contractABI, contractAddress);

       const supply = await contract.totalSupply().call();
       const mintedAmount = await contract.minted().call();
       const userBalance = await contract.balanceOf(address).call();

       console.log(supply);
       console.log(mintedAmount);
       const supplyInDecimal = parseInt(supply, 10);
       const mintedAmountInDecimal = parseInt(mintedAmount, 10);
       const userBalanceInDecimal = parseInt(userBalance, 10);
       console.log(new BigNumber(supplyInDecimal).dividedBy(10 ** 18).toString());
       console.log(new BigNumber(mintedAmountInDecimal).dividedBy(10 ** 18).toString());
       console.log(new BigNumber(userBalanceInDecimal).dividedBy(10 ** 18).toString());
       setTotalSupply(new BigNumber(supplyInDecimal).dividedBy(10 ** 18).toString());
       setMinted(new BigNumber(mintedAmountInDecimal).toString());
       setBalance(new BigNumber(userBalanceInDecimal).dividedBy(10 ** 18).toString());
       const shortenedAddress = address.substring(0, 8) + "..." + address.substring(address.length - 4);
       setConnectedAccount(shortenedAddress);
       }
     } else {
       alert("TronLink未安装")
       // 处理用户拒绝连接的情况
     }
   } catch (error) {
     alert("连接失败")
     console.error("连接失败:", error);
   }
 };

 const handleConnectWallet = () => {
   connectToBlockchain();
 };

  const handleMint = async () => {
    if (window.tronWeb && window.tronWeb.defaultAddress.base58) {
      setLoading(true); // 设置加载状态为true
      const address = window.tronWeb.defaultAddress.base58;
      window.tronWeb.setAddress(address);

      try {
        const contract = await  window.tronWeb.contract(contractABI, contractAddress);
        const tx = await contract.mint(address).send({
          callValue: 90000000  // 8.8 TRX TODO:价格要修改一下
        });
        console.log("Transaction hash:", tx);

        // 等待交易被确认
        let receipt = null;

        // 持续查询交易状态，直到交易被确认
        while (!receipt) {
          receipt = await  window.tronWeb.trx.getTransactionInfo(tx);
          await new Promise(resolve => setTimeout(resolve, 3000)); // 每隔3秒查询一次
        }
        console.log("Transaction receipt:", receipt);
        const userBalance = await contract.balanceOf(address).call();
        const mintedAmount = await contract.minted().call();

        setMinted(new BigNumber(mintedAmount).dividedBy(10 ** 6).toString());
        setBalance(new BigNumber(userBalance).dividedBy(10 ** 6).toString());
      } catch (error) {
        console.error("铸造失败", error);
        alert("Casting failure, insufficient balance, please recharge")
      } finally {
        setLoading(false); // 设置加载状态为false
      }
    }
  };

  return (
    <div className="box">
      {loading && <LoadingIndicator />} {/* 显示全局加载指示器 */}
      {/* <img className="bg" src={backgroundImage} alt="Background" /> */}
      <a className="icon2" href="https://x.com/project_sun_trx">
        <img src={twitterIcon} alt="Twitter Icon" />
      </a>

      <div className="header"></div>
      <img className="nft" src={nftImage} alt="NFT" style={{ width: '20%', height: '35%',  borderRadius: '5%'  }}/>
      <p className="nftBtn" onClick={handleMint}>MINT</p>
      <div className="text1" onClick={handleConnectWallet}>
        {connectedAccount ? <><img src={ethereumIcon} alt="Ethereum Icon" className="ethereumIcon" /> {connectedAccount}</> : "CONNECT WALLET"}
      </div>
      <div className="text2">SUPPLY: {totalSupply === 0 ? "5000" : totalSupply}</div>
      <div className="text3">MINTED: {minted}</div>
      <div className="text4">YOU HAVE: {balance}</div>
    </div>
  );
}

export default MyComponent;

