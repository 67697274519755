// src/App.js 或 src/App.jsx

import React from 'react';
import './App.css';
import MyComponent from './MyComponent'; 

function App() {
  return (
    <div className="App">
      <MyComponent />
    </div>
  );
}

export default App;
